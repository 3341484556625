@use '@angular/material' as mat;

@include mat.core();
@import 'vars';

@font-face {
  font-family: 'Roboto', sans-serif;
  src: url(../fonts/OpenSans-SemiBold.ttf) format('truetype');
  font-weight: 300;
}

html,
*,
div,
body {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
/*   color: #4d4d4d; */
}

:root {
  color-scheme: ligth;
}

.icono-y-texto i {
  color: #183e7b;
  padding-right: 8px;
}
.icono-y-texto .eltexto {
  font-size: 14px;
  margin-left: 5px;
}
.icono-y-texto .negritass {
  font-size: 14px;
  font-weight: bold;
}

.unlink {
  height: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #ff6e20;
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
}
.unlink.naranja {
  color: #ff6e20;
}

.iconolink {
  height: 16px;
  font-size: 14px;
  /*font-weight: 500;*/
  display: inline-block;
  color: #ff6e20;
  cursor: pointer;
}
.iconolink:hover {
  transition: 0.3s;
  color: #ff6e20 !important;
}

.celdaTituloFiltrado{
  color: #ff6f20 !important;
  font-weight: bold !important;
  cursor: pointer !important;
}

celdaTituloFiltrado:hover{
  background:white !important;
}

/**** detalle targetas***/
.tarjeta-borde-color-up {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border-top: 8px solid #42ae4e;
  margin-bottom: 8px;
  background-color: white;
  padding: 0 20px;
}
.tarjeta-borde-color-up.gris {
  border-top: 8px solid #aeaeae;
}
.tarjeta-borde-color-up.verde {
  border-top: 8px solid #42ae4e;
}
.tarjeta-borde-color-up.azul {
  border-top: 8px solid #0054ba;
}
.tarjeta-borde-color-up.naranja {
  border-top: 8px solid #ff9c00;
}
.tarjeta-borde-color-up.morado {
  border-top: 8px solid #9364ce;
}
.tarjeta-borde-color-up.naranja-oscuro {
  border-top: 8px solid #ff6e20;
}
.tarjeta-borde-color-up.rojo {
  border-top: 8px solid #f62b29;
}
.tarjeta-borde-color-up.rojo-oscuro {
  border-top: 8px solid #c5292f;
}

/***  Targetas ***/

.space-cua {
  text-align: right;
}

.tarjeta-borde-color.gris {
  border-left-color: #aeaeae;
}
.tarjeta-borde-color.verde {
  border-left-color: #42ae4e;
}
.tarjeta-borde-color.azul {
  border-left-color: #0054ba;
}
.tarjeta-borde-color.naranja {
  border-left-color: #ff9c00;
}
.tarjeta-borde-color.morado {
  border-left-color: #9364ce;
}
.tarjeta-borde-color.naranja-oscuro {
  border-left-color: #ff6e20;
}
.tarjeta-borde-color.rojo {
  border-left-color: #f62b29;
}
.tarjeta-borde-color.rojo-oscuro {
  border-left-color: #c5292f;
}

.tarjeta-borde-color {
  background-color: white;
  min-height: 90px;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 16px;
  border-left: 8px solid #42ae4e;
  height: 1px;
}

.tarjeta-borde-color .titulo {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #4d4d4d;
  padding: 6px 0;
}
.tarjeta-borde-color .eltexto {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #4d4d4d;
  padding: 6px 0;
}
.detalles {
  cursor: pointer;
}

/*----------------------------------*/


.titulo1 {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #0D457F;
}

.titulo2 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0D457F;
}

.titulo3 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.0875px;
  color: #0D457F;
}

.linea-centro {
  background-image: linear-gradient(#cdcdcd, #cdcdcd);
  background-size: 2px 100%;
  background-repeat: no-repeat;
  background-position: center center;
}



.linea-punteada{
  border-bottom: 3px dashed #cdcdcd;
  margin: 3em;
  color: #cdcdcd;
  text-align: center;
  font-size: 16px;
}

/*------------CUA-------------------*/
.login-titulo1 {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px !important;
}
.login-titulo2 {
  font-size: 14px;
}

.separacion-interseccion-3 {
  margin: 1.5em 0;
}

.titulo-Segmento {
  font-size: 18px;
  font-weight: 500;
  color: #3a3a3a;
  margin-bottom: 11px;
}

/** Segmentos ramos entidades**/

.titulo-Segmento {
  font-size: 18px;
  font-weight: 500;
  color: #3a3a3a;
  margin-bottom: 11px;
}

.unramotxtsub {
  margin: 5px 3px;
  font-size: 14px;
  text-align: center;
  margin-right: 10px;
  padding: 1px 10px;
  color: #a1a1a1;
}

/*----------------------------------*/

// .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
//    color: whitesmoke!important;
// }
::ng-deep .matChip{
  color:whitesmoke!important;
}

::ng-deep mat-icon {
  color: whitesmoke !important; /* Cambia el color del ícono a whitesmoke */
  font-size: 15px !important; /* Asegúrate de que el tamaño del ícono sea 15px */
}

.btn {
  height: 45px !important;
}

.btn-naranja {
  background-color: #ff6b0b !important;
  border-radius: 4px !important;
  box-shadow: none !important;
  color: white !important;
  width: 10em !important;
}

.btn-naranja:hover,
.btn-naranja:focus {
  transition: 0.3s !important;
  background-color: #ff9800 !important;
  color: white;
  box-shadow: none !important;
}

.overlayer {
  position: fixed;
  top: 40%;
  width: auto;
  height: auto;
  display: flex;
  //justify-content: center;
  //align-items: center;
  z-index: 100002;
  margin-left: -45px !important;
}

.loader {
  width: 80px !important;
  height: 80px !important;
}

.sin-padding {
  padding: 0;
}
.material-icons {
  font-size: 30px !important;
}

.btn-flat {
  border: none;
  background: none;
  cursor: pointer;
}

.chevron_color {
  color: #ff6b0b !important;
}

.titulo-anio {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #3a3a3a;
  text-align: center;
}

.div-titulo-anio {
  text-align: center;
  margin-top: 4px;
}

.titulo-secundario {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #4d4d4d;
}

//** Pantalla ramo**///
.otroramotxt {
  cursor: pointer;
  border-bottom: 0px solid #bbdefb;
  margin: 5px 3px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-right: 10px;
  padding: 1px 10px;
  color: #15457e;
}
.otroramotxt.activenaranja {
  transition: 0.3s;
  color: #ff9c00;
  font-weight: bold;
  border-bottom: 1px solid #ff9c00;
}

//**bloques**//

.heder-bloques {
  align-items: flex-end !important;
  display: flex !important;
  flex-wrap: wrap !important;
}
///*****Detalles bono**///
.esquinas-redondas{
  border-radius: 10px;
}

.tarjeta-blanca-totales{
  padding: 20px 0px 10px 0px;
}
.mostrarTotal{
  transition: all 0.2s;
  cursor: pointer;
}
.barraTotal{
  border: 2px solid #cdcdcd;
}
.mostrarTotal:hover .barraTotal{
  border: 2px solid #0054ba;
}

.mostrarTotal.quitarPointer{
  cursor: default;
}

/*---colores ver detalle---*/
.barraTotal.verde{
  color: #42ae4e;
  border-color: #42ae4e;
  background-color: #42ae4e;
}
.barraTotal.gris{
  color: #aeaeae;
  border-color: #aeaeae;
  background-color: #aeaeae;
}
.barraTotal.azul{
  border: 2px solid #0054ba;
  color: #0054ba;
  border-color: #0054ba;
  background-color: #0054ba;
}
.barraTotal.naranja{
  color: #ff9c00;
  border-color: #ff9c00;
  background-color: #ff9c00;
}
.barraTotal.morado{
  color: #9364ce;
  border-color: #9364ce;
  background-color: #9364ce;
}
.barraTotal.naranja-oscuro{
  color: #ff6e20;
  border-color: #ff6e20;
  background-color: #ff6e20;
}
.barraTotal.rojo{
  color: #f62b29;
  border-color: #f62b29;
  background-color: #f62b29;
}
.barraTotal.rojo-oscuro{
  color: #c5292f;
  border-color: #c5292f;
  background-color: #c5292f;
}

///*****Generales**///
.color-icon {
  color: #ff9c00;
}
.marg-left {
  margin-left: auto;
}
.separacion-interseccion-3 {
  margin: 1.5em 0;
}
.bg-gris {
  background-color: #f8f8f8;
}
.elementofake {
  height: 70px;
}
.negritas {
  font-weight: 500;
}

.negritas.azul{
  color: #00337f;
}
.negritas.verde{
  color: #42ae4e;
}
.negritas.naranja{
  color: #ff6e20;
}


.pipe {
  font-size: 20px;
  font-weight: bold;
}
.pipe.verde {
  color: #42ae4e;
}

.pipe.naranja{
  color: #ff6e20;
}

/*----Excel--*/
.exportar-excel{
  font-size: 13px;
}
.exportar-excel, .exportar-excel i{
  color: #183e7b;
}
.dt-button.buttons-excel{
  background: none;
  border: 0;
}
.dt-button.buttons-excel:hover, .dt-button.buttons-excel:focus, .dt-button.buttons-excel:active {
  transition: 0.3s;
  background-color: #d7e4ff !important;
  border: 0 !important;
  background-image: none !important;
}

/*------------------------------------------*/

input {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #0c2040;
}
.contenedor-titulo-detalles{
  background-color: white;
  box-shadow: 0px 3px 8px rgba(0,0,0,0.1);
  padding: 10px 30px;
}

.contenedor-linea-horizontal {
  position: relative;
  padding: 10px 10px;
}

.contenedor-linea-horizontal .linea-horizontal {
  position: absolute;
  top: -20px;
  width: 90%;
  border-top: 1px solid #cccccc;
  left: 4%;
}

.linea-horizontal-simple {
  border-bottom: 1px solid #e2e2e2;
}

.separacion-interseccion-1 {
  margin: 7em 0;
}
.separacion-interseccion-2 {
  margin: 3em 0;
}
.separacion-interseccion-3 {
  margin: 1.5em 0;
}

.tabla-icono td {
  font-size: 10px;
}

/*-------------------------------*/
.login-titulo1 {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.login-titulo2 {
  font-size: 14px;
}

.contenedor-input {
  position: relative;
}

.icono-en-input {
  position: absolute;
  right: 0px;
  bottom: 25px;
  font-size: 15px !important;
  color: #cdcdcd;
}

.input-login {
  background-color: #fafafa !important;
  padding-left: 10px !important;
}

/*---tarjetas de bloques----*/
.contenedor-tarjeta-bloque {
  width: 45%;
  margin-right: 40px;
  margin-bottom: 20px;
}
.tarjeta-bloque {
  background-color: white;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 30px;

  margin-bottom: 30px;
}

.titulo-Bloque {
  font-weight: bold;
  margin: 20px 0;
}
.barrita-color {
  display: inline-block;
  border: 2px solid grey;
  height: 20px;
  border-radius: 10px;
  margin-right: 9px;
}
.fila-periodo {
  height: 72px;
  padding-right: 20px;
}

.seccion2-heder {
  text-align: right;
  padding-right: 20px;
}

.titulo-Bloque.verde,
.titulo-Bloque.verde .barrita-color {
  color: #42ae4e;
  border-color: #42ae4e;
}
.titulo-Bloque.gris,
.titulo-Bloque.gris .barrita-color {
  color: #aeaeae;
  border-color: #aeaeae;
}
.titulo-Bloque.azul,
.titulo-Bloque.azul .barrita-color {
  color: #0054ba;
  border-color: #0054ba;
}
.titulo-Bloque.naranja,
.titulo-Bloque.naranja .barrita-color {
  color: #ff9c00;
  border-color: #ff9c00;
}
.titulo-Bloque.morado,
.titulo-Bloque.morado .barrita-color {
  color: #9364ce;
  border-color: #9364ce;
}
.titulo-Bloque.naranja-oscuro,
.titulo-Bloque.naranja-oscuro .barrita-color {
  color: #ff6e20;
  border-color: #ff6e20;
}
.titulo-Bloque.rojo,
.titulo-Bloque.rojo .barrita-color {
  color: #f62b29;
  border-color: #f62b29;
}
.titulo-Bloque.rojo-oscuro,
.titulo-Bloque.rojo-oscuro .barrita-color {
  color: #c5292f;
  border-color: #c5292f;
}


/*---Icono-Sub---*/
.contenedor-icono {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.img-icono {
  width: 100%;
  height: auto;
}

//***** Targeta acumulado ***///4
.linea-horizontal-simple {
  border-bottom: 1px solid #e2e2e2;
}
.contenedor-tarjeta-info-1 {
  position: relative;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1;
}
.contenedor-tarjeta-info-2 {
  position: relative;
  top: -70px;
  z-index: 2;
}

.detalles {
  cursor: pointer;
}
.tarjeta-borde-color .detalles:hover,
.tarjeta-blanca-datos .detalles:hover {
  transition: 0.3s;
  color: #fc6c25;
}
.tarjeta-borde-color .detalles,
.tarjeta-blanca-datos .detalles {
  text-decoration: underline;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #4d4d4d;
  cursor: pointer;
}

.tarjeta-blanca-datos {
  background-color: white;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
  padding: 10px 30px;
  margin-bottom: 30px;
}

.detalles-componente:hover {
  transition: 0.3s;
  color: #fc6c25;
}

.detalles-componente {
  transition: 0.3s;
  text-decoration: underline;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #4d4d4d;
  cursor: pointer;
}

.chevron_color_bono {
  color: #d3d3d3 !important;
  cursor: pointer !important;
}

.chevron_color_bono:hover {
  transition: 0.3s;
  color: #fc6c25 !important;
  cursor: pointer !important;
}

.padding-0-5 {
  padding: 0px 5px;
}
.padding-0-10 {
  padding: 0px 10px;
}
.padding-10-0 {
  padding: 10px 0px;
}
.padding-20-0 {
  padding: 20px 0;
}
.titulo-Segmento {
  font-size: 18px;
  font-weight: 500;
  color: #3a3a3a;
  margin-bottom: 11px;
}
.titulo-Negritas {
  font-size: 16px;
  font-weight: bold;
  color: #4d4d4d;
}

.negritas {
  font-weight: 500;
}



$color-contrast: (
  50: var(color-primary-humo),
  100: var(--color-primary-humo),
  200: var(--color-primary-humo),
  300: var(--color-primary-humo),
  400: var(--color-primary-humo),
  500: var(--color-primary-humo),
  600: var(--color-primary-humo),
  700: var(--color-primary-humo),
  800: var(--color-primary-humo),
  900: var(--color-primary-humo),
  A100: var(--color-primary-humo),
  A200: var(--color-primary-humo),
  A400: var(--color-primary-humo),
  A700: var(--color-primary-humo)
);

$gnp-orange: (
  50: var(--color-inks-naranja-gnp-100),
  100: var(--color-inks-naranja-gnp-100),
  200: var(--color-inks-naranja-gnp-200),
  300: var(--color-inks-naranja-gnp-300),
  400: var(--color-inks-naranja-gnp-400),
  500: var(--color-inks-naranja-gnp),
  600: var(--color-inks-naranja-gnp-600),
  700: var(--color-inks-naranja-gnp-700),
  800: var(--color-inks-naranja-gnp-800),
  900: var(--color-inks-naranja-gnp-800),
  A100: var(--color-inks-naranja-gnp),
  A200: var(--color-inks-naranja-gnp),
  A400: var(--color-inks-naranja-gnp),
  A700: var(--color-inks-naranja-gnp),
  contrast: $color-contrast
);

$gnp-blue: (
  50: var(--color-inks-azul-gnp-50),
  100: var(--color-inks-azul-gnp-100),
  200: var(--color-inks-azul-gnp-200),
  300: var(--color-inks-azul-gnp-300),
  400: var(--color-inks-azul-gnp-400),
  500: var(--color-inks-azul-gnp),
  600: var(--color-inks-azul-gnp-500),
  700: var(--color-inks-azul-gnp-600),
  800: var(--color-inks-azul-gnp-800),
  900: var(--color-inks-azul-gnp-900),
  A100: var(--color-inks-azul-gnp),
  A200: var(--color-inks-azul-gnp),
  A400: var(--color-inks-azul-gnp),
  A700: var(--color-inks-azul-gnp),
  contrast: $color-contrast
);

$gnp-darker: (color-neutral-darker);

$gnp-typography: mat.define-typography-config(
  $font-family: 'Open Sans, sans-serif'
);

$gnp-primary: mat.define-palette($gnp-orange);
$gnp-accent: mat.define-palette($gnp-blue, A200, A100, A400);
$gnp-warn: mat.define-palette(mat.$red-palette);

$gnp-theme: mat.define-light-theme(
  (
    color: (
      primary: $gnp-primary,
      accent: $gnp-accent,
      warn: $gnp-warn
    ),
    typography: $gnp-typography,
    density: 0
  )
);

@include mat.all-component-themes($gnp-theme);
@include mat.core-color($gnp-theme);
